<template>
  <b-overlay
      :show="product === null || showOverlay"
      rounded="sm"
  >

    <!--    Editor Modal  -->
    <b-modal
        id="modal-Editor"
        centered
        ok-only
        ok-title="ثبت"
        title="افزودن کد html"
        @ok.preventDefault()="customButtonClick"
    >
      <b-row>
        <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
        <b-col cols="12">
          <textarea v-model="rawHTML" class="form-control"></textarea>
        </b-col>
      </b-row>
    </b-modal>

    <component :is="product === null ? 'div' : 'b-card'">
      <b-tabs
          v-if="product !== null"
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
                icon="ClipboardIcon"
                size="16"
                class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">اطلاعات محصول</span>
          </template>

          <!-- Media -->
          <b-media class="my-2">
            <template>
              <b-avatar
                  ref="previewEl"
                  :src="base64ImageSrc"
                  size="300px"
                  rounded
              />
            </template>
            <div class="d-flex flex-wrap mt-1">
              <b-button
                  variant="primary"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    type="file"
                    class="d-none"
                    @input="makeBase64Pic"
                >
                <span class="d-none d-sm-inline">+ عکس</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                  variant="outline-secondary"
                  class="ml-1"
                  @click="deleteProductPic()"
              >
                <span class="d-none d-sm-inline">حذف</span>
                <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
            </div>
          </b-media>

          <div>

            <b-form>
              <b-row>

                <!-- Field:  Title -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="عنوان"
                      label-for="Title"
                  >
                    <b-form-input
                        id="Title"
                        v-model="product.title"
                    />
                    <small v-if="product.title.length > 65" class="text-danger mt-2">
                      عنوان محصول نباید بیشتر از 65 کاراکتر باشد
                    </small>
                  </b-form-group>
                </b-col>

                <!-- Field: isFree -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="وضعیت"
                      label-for="user-status"
                  >
                    <v-select
                        v-model="product.isFree"
                        :options="isFreeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: isAvailable -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="موجودیت"
                      label-for="isAvailable"
                  >
                    <v-select
                        v-model="product.available"
                        :options="isAvailableOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="isAvailable"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field:  price -->
                <b-col
                    v-if="!product.isFree"
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="قیمت (تومان)"
                      label-for="price"
                  >
                    <b-form-input
                        id="price"
                        v-model="product.price"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field:  beforeDiscount -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="قیمت قبلی (خط خورده)"
                      label-for="beforeDiscount"
                  >
                    <b-form-input
                        id="beforeDiscount"
                        v-model="product.beforeDiscount"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field:  subtitle -->
                <b-col
                    cols="12"
                >
                  <b-form-group
                      label="سوتیتر"
                      label-for="subtitle"
                  >
                    <b-form-input
                        id="subtitle"
                        v-model="product.subtitle"
                    />
                  </b-form-group>
                </b-col>

                <!-- Field:  description -->
                <b-col cols="12">
                  <b-form-group
                      class="mb-2"
                      label="توضیحات"
                      label-for="product-content"
                  >
                    <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="product.description"></ckEditorMain>
                  </b-form-group>
                </b-col>

                <!-- Field: productCategoryId -->
                <b-col v-if="categories !== null"
                       cols="12"
                       md="4"
                >
                  <b-form-group
                      label="دسته بندی"
                      label-for="productCategoryId"
                  >
                    <v-select
                        v-model="product.productCategoryId"
                        :options="categories"
                        label="name"
                        :reduce="name => name.productCategoryId"
                        :clearable="false"
                        input-id="productCategoryId"
                        disabled
                    />
                  </b-form-group>
                </b-col>

                <!-- Field: type -->
                <b-col
                    cols="12"
                    md="4"
                >
                  <b-form-group
                      label="انتخاب نوع محصول"
                      label-for="type"
                  >
                    <v-select
                        v-model="product.type"
                        :options="types"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="productCategoryId"
                        disabled
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </b-form>

            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="updateProduct"
            >
              ثبت
            </b-button>
          </div>

        </b-tab>

      </b-tabs>
    </component>

    <b-card class="mt-5">

      <b-row>
        <b-col
            cols="12"
        >
          <h2>تریلر</h2>
          <b-row>
            <!-- Field: trailerFile -->
            <b-col
                v-if="base64Trailer === '' || base64Trailer === null"
                cols="12"
            >
              <b-button
                  class="mt-2 col-6"
                  variant="warning"
                  @click="$refs.refInputEl2.click()"
              >
                <input
                    ref="refInputEl2"
                    type="file"
                    class="d-none"
                    @change="select"
                >
                <span class="">انتخاب تریلر</span>
              </b-button>

              <b-button
                  variant="primary"
                  class="mt-2 mb-sm-0 ml-2 mr-sm-1 col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="upload"
              >
                ثبت
              </b-button>

              <b-progress
                  v-if="uploaded !== 0 && uploaded !== 100"
                  :value="uploaded"
                  max="100"
                  variant="primary"
                  class=" mt-2"
              />

            </b-col>

            <b-col
                v-else
                cols="12"
            >
              <video
                  class="col-10"
                  controls
                  style="max-height: 30rem;"
              >
                <source
                    size=""
                    :src="base64Trailer"
                    type=""
                />
              </video>

              <b-button
                  variant="danger"
                  class="mt-1 mb-sm-0 ml-1 mr-sm-1 col-2 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="deleteTrailer"
              >
                حذف تریلر
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

    </b-card>
  </b-overlay>
</template>

<script>
import {useRouter} from '@core/utils/utils'
import store from '@/store'
import {ref} from '@vue/composition-api'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BTab,
  BTabs,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BMedia,
  BAvatar,
  BOverlay,
  BModal,
  BProgress,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import vSelect from 'vue-select'
import {ProductGetByIdRequest} from "@/libs/Api/Product";
import {ProductCategoryGetAllRequest} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductUpdateRequest} from "@/libs/Api/Product";

// Quill
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {quillEditor} from "vue-quill-editor";
import {MediaDeleteByPictureUrlRequest, MediaDeleteMediaByPictureUrlRequest} from "@/libs/Api/Media";
import axios from "axios";
import {getUserData} from "@/auth/utils";
import Helper from "@/libs/Helper";
import ckEditorMain from "@/views/components/ckEditorMain.vue";


export default {
  title: 'اطلاعات محصول - پنل ادمین مکس ',
  data() {
    return {
      product: null,
      showOverlay: false,
      productId: this.$route.params.id,
      base64ImageSrc: null,
      categories: null,
      showHTML: false,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar'
        },
      },
      base64Trailer:null,
      rawHTML: '',
      isFreeOptions: [
        {label: 'رایگان', value: true},
        {label: 'پولی', value: false}
      ],
      types: [
        {label: 'دوره', value: 1},
        {label: 'فیلم', value: 2},
        {label: 'فایل', value: 3},
        {label: 'پادکست', value: 4},
        {label: 'پکیج', value: 6},
      ],
      isAvailableOptions: [
        {label: 'موجود', value: true},
        {label: 'ناموجود', value: false}
      ],
      //  Upload
      userToken: getUserData().data.token,
      errorAlert: false,
      overlay: false,
      deleteItem: null,
      baseURL: Helper.baseUrl,
      file: null,
      chunks: [],
      uploaded: 0,
      namePictureURL: null,
      priorityPictureURL: null,
      newName: '',
      newPriority: '',
    }
  },
  computed: {
    formData() {
      let formData = new FormData;

      if (this.file !== null) {
        formData.set('file', this.file, `${this.file.name}`);
      }
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: 'https://api.maxterms.com/api/Product/UploadTrailer?productId=' + this.product.productId,
        headers: {
          'Authorization': this.userToken,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          this.uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };
    },
  },
  async created() {
    await this.getProduct();
    await this.getAllProductCategories();
  },
  methods: {
    async getProduct() {
      let _this = this;

      let productGetByIdRequest = new ProductGetByIdRequest(_this);
      productGetByIdRequest.setId(_this.productId);
      await productGetByIdRequest.fetch(function (content) {
        _this.product = content.product;
        _this.base64ImageSrc = 'https://api.maxterms.com/' + _this.product.imageFile;
        _this.product.imageFile = '';
        if (_this.product.trailerFile) {
          _this.base64Trailer = 'https://api.maxterms.com/' + _this.product.trailerFile;
        }else {_this.product.trailerFile = ''};
      }, function (error) {
        console.log(error)
      })
    },
    async getAllProductCategories() {
      let _this = this;
      let defaultGetData = {
        pageNumber: 0,
        count: 0,
      }

      let productCategoryGetAllRequest = new ProductCategoryGetAllRequest(_this);
      productCategoryGetAllRequest.setParams(defaultGetData);
      await productCategoryGetAllRequest.fetch(function (content) {
        _this.categories = content.productCategories;
      }, function (error) {
        console.log(error)
      })
    },
    async updateProduct() {
      let _this = this;
      _this.showOverlay = true

      let productUpdateRequest = new ProductUpdateRequest(_this);
      productUpdateRequest.setParams(_this.product);
      await productUpdateRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.getProduct();
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        }
        fr.readAsDataURL(file);
      });
    },
    async makeBase64Pic(e) {
      const _this = this;
      let file = e.target.files[0]
      _this.base64ImageSrc = URL.createObjectURL(file)
      _this.product.imageFile = await _this.readAsDataURL(file);
    },
    deleteProductPic() {
      this.product.imageFile = '';
      this.base64ImageSrc = null
    },
    select(event) {
      this.file = event.target.files.item(0);
    },
    upload() {
      axios(this.config).then(response => {

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `فایل با موفقیت آپلود شد.`,
          },
        })
        // this.$router.push({name:'apps-post-list'})
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `تریلر شد.`,
          },
        })
        this.getProduct();
      }).catch(error => {
        // this.errorAlert = true;
        console.log(error);
      });
    },
    async makeBase64(e) {
      const _this = this;
      let file = e.target.files[0];
      _this.product.trailerFile = await _this.readAsDataURL(file);
    },
    customButtonClick() {
      this.showHTML = !this.showHTML

      const editor = document.querySelector('.ql-editor')
      if (editor.classList.contains('d-none')) {
        this.product.description = this.rawHTML
        editor.classList.remove('d-none')

      } else {
        this.rawHTML = this.product.description
        editor.classList.add('d-none')
      }
    },
    async deleteTrailer() {
      let _this = this;
      _this.showOverlay = true;

      let pictureUrl = _this.product.trailerFile.split('media/gallery/Trailer/')[1];

      let data = {
        pictureUrl: pictureUrl
      }

      let mediaDeleteByPictureUrlRequest = new MediaDeleteMediaByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setParams(data);
      await mediaDeleteByPictureUrlRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    setEditorContent(content){
      this.product.description = content
    },
  },
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BModal,
    BProgress,
    ckEditorMain,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },

}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
